import correctiveOrdersVendorRoute from "./CorrectiveOrders/corrective.orders.vendor.route";
import ppmOrdersVendorRoute from "./PPMOrders/ppm.orders.vendor.route";
export default {
  routes: [
    {
      path: "work-orders",
      name: "vendor-work-orders",
      component: () => import("./WrapperWorkOrders.vue"),
      redirect: { name: "vendor-corrective" },
      meta: {
        title: "Work Orders",
        roles: [""],
      },
      children: [
        ...correctiveOrdersVendorRoute.routes,
        ...ppmOrdersVendorRoute.routes,
      ],
    },
  ],
};
