export default {
  routes: [
    {
      path: "security",
      name: "settings-security",
      component: () => import("./WrapperSecurity.vue"),
      redirect: { name: "security-MFA" },
      meta: {
        roles: ['']
      },
      children: [
        {
          path: "MFA",
          name: "security-MFA",
          component: () => import("./MFA.vue"),
          meta: {
            roles: ['']
          },
        },
        {
          path: "rules",
          name: "security-rules",
          component: () => import("./SecurityRules.vue"),
          meta: {
            roles: ['']
          },
        },
        {
          path: "alerts",
          name: "security-alerts",
          component: () => import("./SecurityAlerts.vue"),
          meta: {
            roles: ['']
          },
        },
      ]
    },
  ],
};
