export default {
  routes: [
    {
      path: "statement-list",
      name: "statement-list",
      component: () => import("./AccountStatementList.vue"),
      meta: {
        roles: [""],
        permission: ["can_access_accounting_account_statement"],
      },
    },
    {
      path: ":id/statement-list",
      name: "show-statement-list",
      component: () => import("./ShowAccountStatement.vue"),
      meta: {
        roles: [""],
        permission: ["can_access_accounting_account_statement"],
      },
    },
  ],
};
