import { useAuthStore } from "@/stores/auth/auth.store";
import { useSubscriptionDetailsStore } from "@/stores/connect/subscriptions/subscription.details.store.js";
import { extractKeyword } from "@/helpers/url.helper";
const portalName = extractKeyword(window.location.href);

export const userHasModule = function (modules) {
  const account = useAuthStore();
  const availableModules = account.planFeatures || [];
  // Check if the required modules are available
  const hasSomeModules = availableModules?.some((module) =>
    modules?.includes(module),
  );
  // case requiredModules is null , that means the user has access to all modules under this module
  // and the portal must be connect to apply the modules checker to it
  if (modules == null || portalName !== "connect") {
    return true;
  }

  // Check if the required modules are available
  return hasSomeModules;
};


export const isSuspended = async function () {
  // the portal must be connect to find subscription
  if (portalName !== "connect") {
    return false;
  } else {
    const subscriptionInfo = useSubscriptionDetailsStore()
    if (!Object.keys(subscriptionInfo.record).length) {
      await subscriptionInfo.show("")
    }

    return subscriptionInfo.record.is_expired
  }
};
