import ApiClient from "@/helpers/api.helper";
import axios from "@/helpers/http.helper";

class UserAccount extends ApiClient {
  constructor() {
    super("company-users");
  }

  changeDefaultCompany(data) {
    return axios.post(`${this.url}/change-default-company`, data);
  }
}

export default new UserAccount();
