export default {
  routes: [
    {
      path: ":id",
      name: "show-property",
      component: () => import("./WrapperShowProperty.vue"),
      meta: {
        roles: [""],
        permissions: ["can_view_properties"],
      },
      redirect: { name: "show-property-details" },
      children: [
        {
          path: "details",
          name: "show-property-details",
          component: () => import("./ShowPropertyDetails.vue"),
          meta: {
            roles: [""],
            permissions: ["can_view_properties"],
          },
        },
        {
          path: "ownership",
          name: "show-property-ownership",
          component: () => import("./ShowPropertyOwnership.vue"),
          meta: {
            roles: [""],
            permissions: ["can_view_properties"],
          },
        },
        {
          path: "units",
          name: "show-property-units",
          component: () => import("./ShowPropertyUnits.vue"),
          meta: {
            roles: [""],
            permissions: ["can_view_properties"],
          },
        },
        {
          path: "work-orders",
          name: "show-property-work-orders",
          component: () => import("./ShowPropertyWorkOrder.vue"),
          meta: {
            roles: [""],
            permissions: ["can_view_properties"],
          },
        },
        {
          path: "property-logs",
          name: "property-logs",
          component: () => import("@/components/ui/ActivityLog.vue"),
          meta: {
            roles: [""],
            permissions: ["can_view_properties"],
          },
        },
      ],
    },
  ],
};
