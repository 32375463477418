export default {
  routes: [
    {
      path: "property-income",
      name: "property-income",
      component: () => import("./PropertyIncome.vue"),
      meta: {
        roles: [""],
        permission: ["can_access_accounting_income"],
        requiredModules: ["accounting-property-income"],
        disableScrollBehavior: true,
      },
    },
    {
      path: "service-income",
      name: "service-income",
      component: () => import("./ServiceIncome.vue"),
      meta: {
        roles: [""],
        permission: ["can_access_accounting_income"],
        requiredModules: ["accounting-service-income"],
      },
    },
  ],
};
