export default {
  routes: [
    {
      path: "/roles",
      component: () => import("./WrapperRoles.vue"),
      redirect: { name: "admin-roles-list" },
      meta: {
        title: "Roles",
        roles: [""],
      },
      children: [
        {
          path: "",
          name: "admin-roles-list",
          component: () => import("./RolesList.vue"),
          meta: {
            title: "Roles",
            roles: [""],
          },
        },
        {
          path: "create",
          name: "admin-create-role",
          component: () => import("./AdminCreateRole.vue"),
          meta: {
            title: "create role",
            roles: [""],
          },
        },
      ],
    },
  ],
};
