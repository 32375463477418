import correctiveOrdersAdminRoute from "./CorrectiveOrders/corrective.orders.admin.route";

export default {
  routes: [
    {
      path: "/work-orders",
      component: () => import("./WrapperWorkOrders.vue"),
      redirect: { name: "admin-corrective" },
      meta: {
        title: "Work Orders",
        roles: [""],
      },
      children: [...correctiveOrdersAdminRoute.routes],
    },
  ],
};
