export default {
  routes: [
    {
      path: "/404",
      name: "404",
      component: () => import("./404.vue"),
      meta: {
        title: "Page Not Found",
        roles: [""],
        permissions: ["can_access_global"],
      },
    },

    {
      path: "/no-permission",
      name: "no-permission",
      component: () => import("./NoPermission.vue"),
      meta: {
        title: "no_permission",
        roles: [""],
        permissions: ["can_access_global"],
      },
    },
    {
      path: "/upgrade-plan",
      name: "upgrade-plan",
      component: () => import("./UpgradePlan.vue"),
      meta: {
        title: "upgrade_plan",
        roles: [""],
        permissions: ["can_access_global"],
      },
    },
    {
      path: "/suspended",
      name: "suspended",
      component: () => import("./Suspended.vue"),
      meta: {
        title: "suspended",
        isSuspended: true,
        roles: [""],
        permissions: ["can_access_global"],
      },
    },
  ],
};
