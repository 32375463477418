import i18n from "../plugins/I18n";
import { useToastService } from "./toast.helper";
const { showToast } = useToastService();

// Helper to initialize state
export function initializeState() {
  return {
    records: [],
    record: {},
    uiFlags: {
      isLoading: false,
      isUpdating: false,
      isCreating: false,
      isDeleting: false,
    },
    meta: {},
  };
}

// Helper to define getters
export function defineGetters() {
  return {
    // records: (state) => state.records,
    // record: (state) => (id) => state.records.find((item) => item.id === id),
    // uiFlags: (state) => (key) => state.uiFlags[key],
  };
}

// Helper to define actions
export function defineActions(service) {
  return {
    async fetch(params) {
      this.$patch({ uiFlags: { ...this.uiFlags, isLoading: true } });
      try {
        const { data } = await service.get(params);
        this.$patch({ records: data.data });
        this.$patch({ meta: data.meta });

        return true;
      } catch (error) {
        return false;
      } finally {
        this.$patch({ uiFlags: { ...this.uiFlags, isLoading: false } });
      }
    },
    async show(id, params) {
      this.$patch({ uiFlags: { ...this.uiFlags, isLoading: true } });
      try {
        const { data } = await service.show(id, params);
        this.$patch({ record: data.data });
        return true;
      } catch (error) {
        return false;
      } finally {
        this.$patch({ uiFlags: { ...this.uiFlags, isLoading: false } });
      }
    },
    async create(data) {
      this.$patch({ uiFlags: { ...this.uiFlags, isCreating: true } });
      try {
        const response = await service.create(data);

        // Assuming the response has a `data` property
        if (response && response.data) {
          this.$patch({ records: [...this.records, response.data] });
          showToast(
            i18n.global.t("global.massages.success"),
            i18n.global.t("global.massages.create_text"),
            "success",
          );
          return {
            isCreated: true,
            data: response.data,
          };
        }
      } catch (error) {
        return {
          isCreated: false,
          data: error?.response?.data?.errors,
        };
      } finally {
        this.$patch({ uiFlags: { ...this.uiFlags, isCreating: false } });
      }
    },
    async update(id, data) {
      this.$patch({ uiFlags: { ...this.uiFlags, isUpdating: true } });
      try {
        const response = await service.update(id, data);
        const index = this.records.findIndex((item) => item.id === id);
        if (index !== -1) {
          const updatedRecords = [...this.records];
          updatedRecords.splice(index, 1, response.data.data);
          this.$patch({ records: updatedRecords });
        }
        showToast(
          i18n.global.t("global.massages.success"),
          i18n.global.t("global.massages.update_text"),
          "success",
        );
        return {
          isCreated: true,
          data: response.data,
        };
      } catch (error) {
        return {
          isCreated: false,
          data: error?.response?.data?.errors,
        };
      } finally {
        this.$patch({ uiFlags: { ...this.uiFlags, isUpdating: false } });
      }
    },
    async delete(id) {
      this.$patch({ uiFlags: { ...this.uiFlags, isDeleting: true } });
      try {
        await service.delete(id);
        this.$patch({ records: this.records.filter((item) => item.id !== id) });
        showToast(
          i18n.global.t("global.massages.success"),
          i18n.global.t("global.massages.delete_text"),
          "success",
        );
        return true;
      } catch (error) {
        return false;
      } finally {
        this.$patch({ uiFlags: { ...this.uiFlags, isDeleting: false } });
      }
    },
  };
}
