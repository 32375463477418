import accountingRoute from "./Accounting/accounting.route";
import administrationRoute from "./Administration/administration.route";
import assetManagementRoute from "./AssetManagement/asset.management.route";
import communicationRoute from "./Communication/communication.route";
import dashboardRoute from "./Home/home.route";
import libraryRoute from "./Library/library.route";
import locationsRoute from "./Locations/locations.route";
import marketplaceRoute from "./Marketplace/marketplace.route";
import requestsRoute from "./Requests/requests.route";
import settingsRoute from "./Settings/settings.route";
import userManagementRoute from "./UserManagement/user.management.route";
import vendorsTeamsRoute from "./VendorsTeams/vendors.teams.route";
import workOrdersRoute from "./WorkOrders/work.orders.route";
import subscriptionRoute from "./Subscription/subscription.route";
import errorPagesRoute from "./ErrorPages/error.pages.route";
export default {
  routes: [
    ...dashboardRoute.routes,
    ...accountingRoute.routes,
    ...administrationRoute.routes,
    ...assetManagementRoute.routes,
    ...communicationRoute.routes,
    ...libraryRoute.routes,
    ...locationsRoute.routes,
    ...marketplaceRoute.routes,
    ...requestsRoute.routes,
    ...settingsRoute.routes,
    ...userManagementRoute.routes,
    ...vendorsTeamsRoute.routes,
    ...workOrdersRoute.routes,
    ...subscriptionRoute.routes,
    ...errorPagesRoute.routes,
    {
      path: "faqs",
      name: "faqs",
      component: () => import("./Faqs.vue"),
      meta: {
        roles: [""],
        title: "faqs"
      },
    },
  ],
};
