export default {
  routes: [
    {
      path: "plans",
      component: () => import("./WrapperSubscriptionsPlans.vue"),
      redirect: { name: "admin-subscriptions-plans" },
      meta: {
        roles: [""],
      },
      children: [
        {
          path: "",
          name: "admin-subscriptions-plans",
          component: () => import("./AdminSubscriptionsPlans.vue"),
          meta: {
            title: "Subscriptions Plans",
            roles: [""],
          },
        },

        {
          path: "create",
          name: "admin-create-subscriptions-plan",
          component: () => import("./AdminCreateSubscriptionsPlan.vue"),
          meta: {
            title: "create",
            roles: [""],
          },
        },
        {
          path: ":id/edit",
          name: "admin-edit-subscriptions-plan",
          component: () => import("./AdminCreateSubscriptionsPlan.vue"),
          meta: {
            title: "edit",
            roles: [""],
            isEdit: true,
          },
        },
      ],
    },
  ],
};
