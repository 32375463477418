export default {
  routes: [
    {
      path: "features",
      component: () => import("./IndexWrapper.vue"),
      redirect: { name: "admin-subscriptions-features" },
      meta: {
        roles: [""],
      },
      children: [
        {
          path: "",
          name: "admin-subscriptions-features",
          component: () => import("./AdminSubscriptionsFeatures.vue"),
          meta: {
            title: "Features",
            roles: [""],
          },
        },
        // {
        //   path: "create",
        //   name: "admin-create-subscriptions-feature",
        //   component: () => import("./AdminCreateSubscriptionsFeature.vue"),
        //   meta: {
        //     title: "Features",
        //     roles: [""],
        //   },
        // },
        // {
        //   path: ":id/edit",
        //   name: "admin-edit-subscriptions-feature",
        //   component: () => import("./AdminCreateSubscriptionsFeature.vue"),
        //   meta: {
        //     title: "Features",
        //     roles: [""],
        //     isEdit: true,
        //   },
        // },
      ],
    },
  ],
};
