export default {
  routes: [
    {
      path: "vouchers",
      component: () => import("./IndexWrapper.vue"),
      redirect: { name: "admin-subscriptions-vouchers" },
      meta: {
        roles: [""],
      },
      children: [
        {
          path: "",
          name: "admin-subscriptions-vouchers",
          component: () => import("./AdminSubscriptionsVouchers.vue"),
          meta: {
            title: "Vouchers",
            roles: [""],
          },
        },
        {
          path: "create",
          name: "admin-create-subscriptions-voucher",
          component: () => import("./AdminCreateSubscriptionsVouchers.vue"),
          meta: {
            title: "create",
            roles: [""],
          },
        },
        {
          path: ":id/edit",
          name: "admin-edit-subscriptions-voucher",
          component: () => import("./AdminCreateSubscriptionsVouchers.vue"),
          meta: {
            title: "edit",
            roles: [""],
            isEdit: true,
          },
        },
      ],
    },
  ],
};
