import locationPropertiesRoute from "./LocationProperties/location.properties.route";
import locationsSitesRoute from "./LocationSites/locations.sites.route";
import locationsUnitsRoute from "./LocationUnits/locations.units.route";
import locationsAllRoute from "./LocationsAll/locations.all.route";

export default {
  routes: [
    {
      path: "locations-wrappers",
      component: () => import("./WrapperLocations.vue"),
      redirect: { name: "locations" },
      meta: {
        title: "Locations",
        roles: [""],
        permissions: [
          "can_access_all_locations",
          // "can_activate_or_deactivate_locations",
        ],
        requiredModules: null,
      },
      children: [
        ...locationsAllRoute.routes,
        ...locationsSitesRoute.routes,
        ...locationPropertiesRoute.routes,
        ...locationsUnitsRoute.routes,
      ],
    },
  ],
};
