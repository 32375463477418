export default {
  routes: [
    {
      path: "/companies",
      component: () => import("./WrapperCompanies.vue"),
      redirect: { name: "vendor-companies-list" },
      meta: {
        title: "companies",
        roles: [""],
      },
      children: [
        {
          path: "",
          name: "vendor-companies-list",
          component: () => import("./CompaniesList.vue"),
          meta: {
            title: "Companies",
            roles: [""],
          },
        },
        {
          path: ":id",
          name: "vendor-show-company",
          component: () => import("./CompanyShow.vue"),
          meta: {
            title: "Company",
            roles: [""],
          },
        },
      ],
    },
  ],
};
