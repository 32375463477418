export default {
  routes: [
    {
      path: "/faqs",
      component: () => import("./WrapperFaqs.vue"),
      redirect: { name: "admin-faqs-list" },
      meta: {
        title: "faqs",
        roles: [""],
      },
      children: [
        {
          path: "",
          name: "admin-faqs-list",
          component: () => import("./AdminFaqsList.vue"),
          meta: {
            title: "faqs",
            roles: [""],
          },
        },
        {
          path: "create",
          name: "admin-create-faq",
          component: () => import("./AdminCreateFaq.vue"),
          meta: {
            title: "create faq",
            roles: [""],
          },
        },
        {
          path: ":id",
          name: "admin-show-faq",
          component: () => import("./AdminShowFaq.vue"),
          meta: {
            title: "faq",
            roles: [""],
          },
        },
      ],
    },
  ],
};
