import ApiClient from "@/helpers/api.helper";
import axios from "@/helpers/http.helper";

class Auth extends ApiClient {
  constructor() {
    super("auth");
  }

  login(data) {
    return axios.post(`${this.url}/login`, data);
  }

  getMe() {
    return axios.get(`${this.url}/me`);
  }
  logout() {
    return axios.post(`${this.url}/logout`);
  }

  completeRegistration(data) {
    return axios.patch(`${this.url}/complete-registration`, data);
  }

  forgotPassword(email) {
    return axios.post(`${this.url}/forgot`, email);
  }

  resetPassword(data) {
    return axios.post(`${this.url}/reset`, data);
  }
}

export default new Auth();
