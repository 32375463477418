export default {
  routes: [
    {
      path: "/invoices",
      component: () => import("./WrapperInvoices.vue"),
      redirect: { name: "vendor-invoices-list" },
      meta: {
        title: "invoices",
        roles: [""],
      },
      children: [
        {
          path: "",
          name: "vendor-invoices-list",
          component: () => import("./InvoicesList.vue"),
          meta: {
            title: "Companies",
            roles: [""],
          },
        },
        {
          path: "show-invoice/:id",
          name: "show-invoice",
          component: () => import("./WrapperShowInvoice.vue"),
          meta: {
            title: "invoices",
            roles: [""],
          },
          children: [
            {
              path: "",
              name: "show-invoice",
              component: () => import("./ShowInvoice.vue"),
              meta: {
                title: "invoices",
                roles: [""],
              },
            },
          ],
        },
      ],
    },
  ],
};
