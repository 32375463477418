import SecurityRoute from "./Security/security.route";
export default {
  routes: [
    {
      path: "/settings",
      name: "settings",
      component: () => import("./WrapperSettings.vue"),
      redirect: { name: "settings-general" },
      meta: {
        title: "settings",
        roles: [""],
        requiredModules: ["settings"],
        permissions: ["can_access_settings"],
      },
      children: [
        {
          path: "general",
          name: "settings-general",
          component: () => import("./GeneralSettings.vue"),
          meta: {
            roles: [""],
          },
        },
        {
          path: "api",
          name: "settings-api",
          component: () => import("./Api.vue"),
          meta: {
            roles: [""],
          },
        },
        ...SecurityRoute.routes,
        {
          path: "webhooks",
          name: "settings-webhooks",
          component: () => import("./WrapperSettings.vue"),
          meta: {
            roles: [""],
          },
        },
        {
          path: "notification",
          name: "settings-notification",
          component: () => import("./SettingsNotification.vue"),
          meta: {
            roles: [""],
          },
        },
        // {
        //   path: "faqs",
        //   name: "settings-faqs",
        //   component: () => import("../Faqs.vue"),
        //   meta: {
        //     roles: [""],
        //   },
        // },
      ],
    },
  ],
};
