export default {
  routes: [
    {
      path: "index",
      name: "vendor-communication-index",
      component: () => import("./Communication.vendor.vue"),
      meta: {
        title: "communication",
        roles: [""],
      },
      redirect: { name: "communication-message" },
      children: [
        {
          path: "/message",
          name: "vendor-communication-message",
          component: () => import("./CommunicationMessage.vue"),
          meta: {
            title: "communication",
            roles: [""],
          },
        },
      ],
    },
  ],
};
