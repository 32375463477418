import { defineStore } from "pinia";
import {
  initializeState,
  defineGetters,
  defineActions,
} from "@/helpers/store.helper";

import subscriptionDetailsServices from "@/services/connect/subscriptions/subscription.details.services";

export const useSubscriptionDetailsStore = defineStore("subscription_details", {
  state: () => ({
    ...initializeState(),
  }),
  getters: {
    ...defineGetters(),
  },
  actions: {
    ...defineActions(subscriptionDetailsServices),
  },
});
