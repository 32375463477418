export default {
  routes: [
    {
      path: "/services",
      component: () => import("./WrapperMarketplaceServices.vue"),
      redirect: { name: "admin-marketplace-services-list" },
      meta: {
        title: "Services",
        roles: [""],
      },
      children: [
        {
          path: "",
          name: "admin-marketplace-services-list",
          component: () => import("./ServicesList.vue"),
          meta: {
            title: "Services",
            roles: [""],
          },
        },
        {
          path: "create",
          name: "admin-create-marketplace-services",
          component: () => import("./CreateServices.vue"),
          meta: {
            title: "Create Category",
            roles: [""],
            isEdit: false,
          },
        },
        {
          path: ":id/edit",
          name: "admin-edit-marketplace-services",
          component: () => import("./CreateServices.vue"),
          meta: {
            title: "Edit Services",
            roles: [""],
            isEdit: true,
          },
        },
      ],
    },
  ],
};
