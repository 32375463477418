import { useAuthStore } from "../stores/auth/auth.store";
import { userHasModule, isSuspended } from "./module.permission.helper";
import { extractKeyword } from "./url.helper";

export const authGuard = (to, from, next) => {
  const tokenExists = $cookies.isKey(
    `${extractKeyword(window.location.href)}_mc_token`,
  );
  if (tokenExists) {
    // Token exists, redirect to contracts page
    if (extractKeyword(window.location.href) === "admin") {
      next({ name: "admin-dashboard" });
    } else if (extractKeyword(window.location.href) == "vendor") {
      next({ name: "vendor-dashboard" });
    } else {
      next({ name: "connect-dashboard" });
    }
  } else {
    // Token doesn't exist, allow navigation to the requested route
    next();
  }
};

export const guestGuard = (to, from, next) => {
  const tokenExists = $cookies.isKey(`connect_mc_token`);
  if (tokenExists) {
    next();
  } else {
    next({ name: "login" });
  }
};

export const isVendor = (to, from, next) => {
  const tokenExists = $cookies.isKey(`vendor_mc_token`);
  if (tokenExists) {
    next();
  } else {
    next({ name: "login" });
  }
};

export const isAdmin = (to, from, next) => {
  const tokenExists = $cookies.isKey(`admin_mc_token`);
  if (tokenExists) {
    next();
  } else {
    next({ name: "login" });
  }
};

export const userHasPermissions = async function (to, from, next) {
  const account = useAuthStore();
  // Check if the user is authenticated and permissions are loaded
  if (!account.user) {
    try {
      await account.checkAuth(); // Fetch user data
    } catch (error) {
      return next({ name: "login" }); // Redirect to login if fetching fails
    }
  }

  if (await isSuspended()) {
    // Allow access to the suspended page (to avoid infinite redirect) && administration =>subscription-plan page 
    if (to.name == "administration" || to.name == "subscription-plan" || to.name == "suspended") {
      next();
    }
    else {
      next({ name: "suspended" })
    }
  } else {
    const permissions = account.permissions || []; // Default to an empty array if null
    // Check if the required permissions are available
    const hasAllPermissions = to.meta.permissions?.every((permission) =>
      permissions?.includes(permission),
    );
    // Check if the company's plan allows access to the requested module
    const requiredModules = to.meta?.requiredModules;
    const hasPlanAccess = userHasModule(requiredModules);
    if (
      hasAllPermissions ||
      to.meta.permissions?.includes("can_access_global")
    ) {
      // User has all required permissions
      if (hasPlanAccess) {
        next(); // Proceed to the route if the company plan grants access to the module
      } else {
        // Prevent infinite redirect to the upgrade plan page
        if (to.name !== "upgrade-plan") {
          // Redirect to the Upgrade Plan page
          next({ name: "upgrade-plan" });
        } else {
          // Allow access to the upgrade-plan page if already there
          next();
        }
      }
    } else {
      // Check if we are already on the "no-permission" page to avoid redirect loop
      if (to.name !== "no-permission") {
        next({ name: "no-permission" }); // Redirect to a no-permission page
      } else {
        next(); // Allow access to the no-permission page (to avoid infinite redirect)
      }
    }
  }
};
