import { getAccessibleRedirectRoute } from "@/helpers/redirect.helper";

export default {
  routes: [
    {
      path: "/dashboard",
      component: () => import("./WrapperHome.vue"),
      name: "connect-index-dashboard",
      meta: {
        title: "dashboard",
        roles: [""],
        permissions: ["can_access_dashboard"],
        requiredModules: ["simple-dashboard", "full-dashboard"],
      },
      // redirect: { name: "connect-dashboard" },
      redirect: (to) => {
        const parentRoute = to.matched.find(
          (route) => route.name === "connect-index-dashboard",
        );
        const childRoutes = parentRoute.children || [];
        const redirectRoute = getAccessibleRedirectRoute(childRoutes);
        return redirectRoute;
      },
      children: [
        {
          path: "overview",
          name: "connect-dashboard",
          component: () => import("./Dashboard/Dashboard.vue"),
          meta: {
            roles: [""],
            permissions: ["can_access_dashboard"],
            requiredModules: ["simple-dashboard"],
          },
        },
        {
          path: "reports-details",
          name: "reports-details",
          component: () => import("./ReportingDetails/ReportingDetails.vue"),
          meta: {
            roles: [""],
            permissions: ["can_access_reporting_details"],
            requiredModules: ["full-dashboard"],
          },
        },
        {
          path: "recent-activity",
          name: "recent-activity",
          component: () => import("./RecentActivity/RecentActivity.vue"),
          meta: {
            roles: [""],
            permissions: ["can_access_global"],
            requiredModules: ["full-dashboard"],
          },
        },
        {
          path: "import-export",
          name: "import-export",
          component: () => import("./ImportExport/ImportExport.vue"),
          meta: {
            roles: [""],
            permissions: [
              "can_start_import_process_dashboard",
              "can_export_selected_data_dashboard",
            ],
            requiredModules: ["full-dashboard"],
          },
        },
      ],
    },
  ],
};
