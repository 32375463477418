import assetRoute from "./Asset/asset.route";
import partsInventoryRoute from "./PartsInventory/parts.inventory.route";

export default {
  routes: [
    {
      path: "asset-management",
      component: () => import("./WrapperAssetManagement.vue"),
      redirect: { name: "asset" },
      meta: {
        roles: [""],
      },
      children: [...assetRoute.routes, ...partsInventoryRoute.routes],
    },
  ],
};
