import correctiveOrdersRoute from "./CorrectiveOrders/corrective.orders.route";
import ppmOrdersRoute from "./PPMOrders/ppm.orders.route";

export default {
  routes: [
    {
      path: "work-orders",
      component: () => import("./WrapperWorkOrders.vue"),
      redirect: { name: "corrective" },
      meta: {
        title: 'Work Orders',
        roles: ['']

      },
      children: [
        ...correctiveOrdersRoute.routes,
        ...ppmOrdersRoute.routes
      ]
    },
  ],
};
