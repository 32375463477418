import { defineStore } from "pinia";
import {
  initializeState,
  defineGetters,
  defineActions,
} from "@/helpers/store.helper";
import companySettingsServices from "@/services/connect/administration/companySettings/company.settings.services.js";

export const useCompanySettingsStore = defineStore("companySettings", {
  state: () => ({
    ...initializeState(),
  }),
  getters: {
    ...defineGetters(),
  },
  actions: {
    ...defineActions(companySettingsServices),
  },
});
