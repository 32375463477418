export default {
  routes: [
    {
      path: "/market-place",
      component: () => import("./WrapperMarketplace.vue"),
      redirect: { name: "admin-marketplace-list" },
      meta: {
        title: "Marketplace",
        roles: [""],
      },
      children: [
        {
          path: "",
          name: "admin-marketplace-list",
          component: () => import("./MarketplaceList.vue"),
          meta: {
            title: "Marketplace",
            roles: [""],
          },
        },
        {
          path: "edit/:id",
          name: "admin-edit-marketplace",
          component: () => import("./CreateMarketplace.vue"),
          meta: {
            title: "Show Marketplace",
            roles: [""],
            isEdit: true,
          },
        },
      ],
    },
  ],
};
