import { useAuthStore } from "@/stores/auth/auth.store";

export function getAccessibleRedirectRoute(
  childRoutes,
  defaultRoute = "upgrade-plan",
) {
  const authStore = useAuthStore();
  const availableModules = authStore.planFeatures || [];
  // Find the first accessible route, respecting the order in the array [availableModules]
  const accessibleRoute = childRoutes.find((route) => {
    const requiredModules = route.meta?.requiredModules || [];
    const isAccessible =
      requiredModules.length === 0 ||
      requiredModules.some((module) => availableModules.includes(module));
    return isAccessible;
  });

  // Return the route name or fallback
  return { name: accessibleRoute?.name || defaultRoute };
}
