export default {
  routes: [
    {
      path: "/asset",
      component: () => import("./AssetIndex.vue"),
      meta: {
        title: "asset_management.assets",
        roles: [""],
        requiredModules: ["asset"],
      },
      children: [
        {
          path: "",
          name: "assets-management",
          component: () => import("./WrapperAsset.vue"),
          meta: {
            roles: [""],
            permissions: ["can_access_assets", "can_list_assets"],
          },
        },
        {
          path: "create",
          name: "create-asset",
          component: () => import("./CreateAsset.vue"),
          meta: {
            roles: [],
            permissions: ["can_create_assets"],
          },
        },
        {
          path: "edit/:id",
          name: "edit-asset",
          component: () => import("./CreateAsset.vue"),
          meta: {
            isEdit: true,
            roles: [],
            permissions: ["can_update_assets"],
          },
        },
        {
          path: ":id",
          name: "asset-details",
          component: () => import("./AssetDetailsWrapper.vue"),
          meta: {
            roles: [""],
            permissions: ["can_view_assets"],
          },
          redirect: { name: "info" },
          children: [
            {
              path: "info",
              name: "info",
              component: () => import("./AssetDetails.vue"),
              meta: {
                permissions: ["can_view_assets"],
                roles: [""],
              },
            },

            {
              path: "inventory-line",
              name: "inventory-line",
              component: () => import("./AssetInventoryLine.vue"),
              meta: {
                permissions: ["can_view_assets"],
                roles: [],
              },
            },
            {
              path: "performance",
              name: "performance",
              component: () => import("./AssetPerformance.vue"),
              meta: {
                permissions: ["can_view_assets"],
                roles: [],
              },
            },
            {
              path: "corrective-orders",
              name: "corrective-orders",
              component: () => import("./AssetBoard/CorrectiveBoard.vue"),
              meta: {
                permissions: ["can_view_assets"],
                roles: [],
              },
            },
            {
              path: "ppm-orders",
              name: "ppm-orders",
              component: () => import("./AssetBoard/PPMBoard.vue"),
              meta: {
                permissions: ["can_view_assets"],
                roles: [],
              },
            },
            {
              path: "assets-logs",
              name: "assets-logs",
              component: () => import("@/components/ui/ActivityLog.vue"),
              meta: {
                permissions: ["can_view_assets"],
                roles: [],
              },
            },
          ],
        },
      ],
    },
  ],
};
