import vendorRoute from "./AdminVendors/vendors.admin.route";
import companiesAdminRoute from "./AdminCompanies/companies.admin.route";
import marketplaceAdminRoute from "./AdminMarketplace/marketplace.admin.route";
import ownersAdminRoute from "./AdminOwners/owners.admin.route";
import rolesAdminRoute from "./AdminRoles/roles.admin.route";
import usersAdminRoute from "./AdminUsers/users.admin.route";
import workOrdersAdminRoute from "./AdminWorkOrders/work.orders.admin.route";
import communicationAdminRoute from "./AdminCommunication/communication.admin.route";
import faqsAdminRoute from "./AdminFaqs/faqs.admin.route";
import faqsCategoriesAdminRoute from "./AdminFaqs/faqs.categories.admin.route";
import subscriptionsAdminRoute from "./AdminSubscriptions/subscriptions.admin.route";
export default {
  routes: [
    ...ownersAdminRoute.routes,
    ...companiesAdminRoute.routes,
    ...usersAdminRoute.routes,
    ...workOrdersAdminRoute.routes,
    ...marketplaceAdminRoute.routes,
    ...rolesAdminRoute.routes,
    ...vendorRoute.routes,
    ...communicationAdminRoute.routes,
    ...faqsAdminRoute.routes,
    ...faqsCategoriesAdminRoute.routes,
    ...subscriptionsAdminRoute.routes,
  ],
};
