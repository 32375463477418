export default {
  routes: [
    {
      path: "/categories",
      component: () => import("./WrapperMarketplaceCategories.vue"),
      redirect: { name: "admin-marketplace-categories-list" },
      meta: {
        title: "Categories",
        roles: [""],
      },
      children: [
        {
          path: "",
          name: "admin-marketplace-categories-list",
          component: () => import("./CategoriesList.vue"),
          meta: {
            title: "Categories",
            roles: [""],
          },
        },
        {
          path: "create",
          name: "admin-create-marketplace-categories",
          component: () => import("./CreateCategory.vue"),
          meta: {
            title: "Create Category",
            roles: [""],
            isEdit: false,
          },
        },
        {
          path: ":id/edit",
          name: "admin-edit-marketplace-categories",
          component: () => import("./CreateCategory.vue"),
          meta: {
            title: "Edit Category",
            roles: [""],
            isEdit: true,
          },
        },
      ],
    },
  ],
};
