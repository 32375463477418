import propertyOwnersRoute from "./PropertyOwners/property.owners.route";
import unitResidentsRoute from "./UnitResidents/unit.residents.route";
import usersRoute from "./Users/users.route";

export default {
  routes: [
    {
      path: "user-management",
      name: "user-management",
      component: () => import("./WrapperUserManagement.vue"),
      redirect: { name: "users" },
      children: [
        ...usersRoute.routes,
        ...propertyOwnersRoute.routes,
        ...unitResidentsRoute.routes
      ]
    }
  ]
};
