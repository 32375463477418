export default {
  routes: [
    {
      path: "/vendor",
      name: "admin-vendors",
      component: () => import("./WrapperVendors.vue"),
      redirect: { name: "admin-vendors-list" },
      meta: {
        title: "Vendors",
        roles: [""],
      },
      children: [
        {
          path: "",
          name: "admin-vendors-list",
          component: () => import("./VendorsList.vue"),
          meta: {
            title: "Vendors",
            roles: [""],
          },
        },
      ],
    },
  ],
};
