export default {
  routes: [
    {
      path: "/work-order-template",
      name: "work-order-template-index",
      component: () => import("./WrapperOrderTemplate.vue"),
      meta: {
        title: "library.work_order_template",
        roles: [""],
        requiredModules: ["library-work-order-template-corr-and-ppm"],
      },
      children: [
        {
          path: "",
          name: "work-order-template",
          component: () => import("./OrdersTemplates.vue"),
          meta: {
            roles: [""],
            permissions: [
              "can_list_work_order_template",
              "can_access_work_order_template",
            ],
          },
        },
        {
          path: "edit/:id",
          name: "edit-template",
          component: () => import("./EditTemplate.vue"),
          meta: {
            permissions: ["can_update_work_order_template"],
            roles: [""],
          },
        },
      ],
    },
  ],
};
