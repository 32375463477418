import { useAuthStore } from "../stores/auth/auth.store";
import { extractKeyword } from "./url.helper";
const portalName = extractKeyword(window.location.href);

export const userHasAction = function (permission) {
  const account = useAuthStore();

  const permissions = account.permissions || []; // Default to an empty array if null

  // Check if the required permissions are available
  if (permissions.includes(permission) || portalName !== "connect") {
    return true;
  } else {
    return false;
  }
};
