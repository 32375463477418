export default {
  routes: [
    {
      path: ":id",
      name: "admin-show-company",
      component: () => import("./WrapperShowCompany.vue"),
      meta: {},
      redirect: { name: "show-company-details" },
      children: [
        {
          path: "details",
          name: "show-company-details",
          component: () => import("./AdminCompanyDetails.vue"),
          meta: {
            roles: [""],
          },
        },
        {
          path: "vendors",
          name: "show-company-vendors",
          component: () => import("./AdminCompanyVendors.vue"),
          meta: {
            roles: [""],
          },
        },
        {
          path: "subscription",
          name: "show-company-subscription",
          component: () => import("./AdminCompanySubscription.vue"),
          redirect: { name: "show-company-subscription-invoices" },
          meta: {
            roles: [""],
          },
          children: [
            {
              path: "invoices",
              name: "show-company-subscription-invoices",
              component: () => import("./AdminCompanyInvoices.vue"),
              meta: {
                roles: [""],
              },
            },
            {
              path: "features",
              name: "show-company-subscription-features",
              component: () => import("./AdminCompanyFeatures.vue"),
              meta: {
                roles: [""],
              },
            },
            {
              path: "addons",
              name: "show-company-subscription-addons",
              component: () => import("./AdminCompanyAddons.vue"),
              meta: {
                roles: [""],
              },
            },
          ],
        },
      ],
    },
  ],
};
