import marketplaceAdminRoute from "./Marketplace/marketplace.admin.route";
import marketplaceCategoriesAdminRoute from "./MarketplaceCategories/marketplace.categories.admin.route";
import marketplaceServicesAdminRoute from "./MarketplaceServices/marketplace.services.admin.route";

export default {
  routes: [
    {
      path: "index",
      component: () => import("./WrapperMarketplace.vue"),
      redirect: { name: "admin-marketplace-list" },
      meta: {
        title: "Marketplace",
        roles: [""],
      },
      children: [
        ...marketplaceAdminRoute.routes,
        ...marketplaceCategoriesAdminRoute.routes,
        ...marketplaceServicesAdminRoute.routes,
      ],
    },
  ],
};
