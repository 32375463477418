export default {
  routes: [
    {
      path: "/faq-categories",
      component: () => import("./WrapperFaqs.vue"),
      redirect: { name: "admin-faq-categories-list" },
      meta: {
        title: "faqs",
        roles: [""],
      },
      children: [
        {
          path: "",
          name: "admin-faq-categories-list",
          component: () => import("./AdminFaqsCategoriesList.vue"),
          meta: {
            title: "faq-categories",
            roles: [""],
          },
        },
        {
          path: "create",
          name: "admin-create-faq-categories",
          component: () => import("./AdminCreateCategoryFaq.vue"),
          meta: {
            title: "create faq categories",
            roles: [""],
          },
        },
      ],
    },
  ],
};
