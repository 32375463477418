import showCorrectiveVendorRoute from "./ShowCorrective/show.corrective.vendor.route";

export default {
  routes: [
    {
      path: "order-corrective",
      name: "vendor-index",
      component: () => import("./CorrectiveIndex"),
      redirect: { name: "vendor-corrective" },
      meta: {
        title: "corrective",
        roles: [""],
      },
      children: [
        {
          path: "",
          name: "vendor-corrective",
          component: () => import("./WrapperCorrective.vue"),
          meta: {
            title: "corrective",
            roles: [""],
          },
          redirect: { name: "vendor-corrective-list-view" },
          children: [
            {
              path: "list",
              name: "vendor-corrective-list-view",
              component: () => import("./ListView/CorrectiveList.vue"),
              meta: {
                title: "list",
                roles: [""],
              },
            },
          ],
        },
        {
          path: ":id",
          name: "vendor-wrapper-show-corrective",
          component: () => import("./ShowCorrective/WrapperShowCorrective.vue"),
          props: true,
          meta: {
            title: "detail",
            roles: [""],
          },
          redirect: { name: "vendor-corrective-details" },
          children: [...showCorrectiveVendorRoute.routes],
        },

        {
          path: ":id/create-task",
          name: "vendor-create-tasks",
          component: () => import("./CreateTask.vue"),
          meta: {
            title: "detail",
            roles: [""],
          },
        },
      ],
    },
  ],
};
