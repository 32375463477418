import showCompanyRoutes from "./CompanyDetails/companies.admin.show.route";
export default {
  routes: [
    {
      path: "/companies",
      component: () => import("./AdminCompaniesIndex.vue"),
      redirect: { name: "admin-companies-list" },
      meta: {
        title: "companies",
        roles: [""],
      },
      children: [
        {
          path: "",
          name: "admin-companies-list",
          component: () => import("./AdminWrapperCompanies.vue"),
          meta: {
            title: "Companies",
            roles: [""],
          },
        },
        {
          path: "create",
          name: "admin-create-company",
          component: () => import("./AdminCreateCompany.vue"),
          meta: {
            title: "create company",
            roles: [""],
          },
        },
        {
          path: "",
          name: "show-company-index",
          component: () => import("./CompanyDetails/IndexShowCompany.vue"),
          redirect: { name: "admin-show-company" },
          children: [...showCompanyRoutes.routes],
        },
        {
          path: ":id/edit",
          name: "admin-edit-company",
          component: () => import("./AdminCreateCompany.vue"),
          meta: {
            title: "edit company",
            roles: [""],
            isEdit: true,
          },
        },
        {
          path: ":id/change-plan",
          name: "admin-change-plan",
          component: () => import("./CompanyDetails/AdminChangePlan.vue"),
        },
      ],
    },
  ],
};
