export default {
  routes: [
    {
      path: "requests",
      component: () => import("./WrapperSubscriptionsRequests.vue"),
      redirect: { name: "admin-subscriptions-requests" },
      meta: {
        title: "Subscriptions Requests",
        roles: [""],
      },
      children: [
        {
          path: "",
          name: "admin-subscriptions-requests",
          component: () => import("./AdminSubscriptionsRequests.vue"),
          meta: {
            roles: [""],
          },
        },
        {
          path: ":id/details",
          name: "admin-show-subscriptions-requests",
          component: () => import("./AdminShowSubscriptionsRequests.vue"),
          meta: {
            roles: [""],
          },
        },
      ],
    },
  ],
};
