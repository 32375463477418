import subscriptionVouchersAdminServices from "../../../services/admin/subscriptions/subscription.vouchers.admin.services";
import subscriptionsAddonsCategoryAdminRoute from "./AdminAddonsCategorySubscriptions/subscriptions.addons.category.admin.route";
import subscriptionsAddonsAdminRoute from "./AdminAddonsSubscriptions/subscriptions.addons.admin.route";
import subscriptionsFeaturesAdminRoute from "./AdminSubscriptionsFeatures/subscriptions.features.admin.route";
import subscriptionsPlansAdminRoute from "./AdminSubscriptionsPlans/subscriptions.plans.admin.route";
import subscriptionsVouchersAdminRoute from "./AdminSubscriptionsVouchers/subscriptions.vouchers.admin.route";
import subscriptionsRequestsAdminRoute from "./AdminSubscriptionRequests/subscriptions.requests.admin.route";
export default {
  routes: [
    {
      path: "/subscriptions",
      component: () => import("./WrapperSubscriptions.vue"),
      redirect: { name: "admin-subscriptions-plans" },
      meta: {
        title: "Subscriptions",
        roles: [""],
      },
      children: [
        ...subscriptionsPlansAdminRoute.routes,
        ...subscriptionsAddonsAdminRoute.routes,
        ...subscriptionsAddonsCategoryAdminRoute.routes,
        ...subscriptionsVouchersAdminRoute.routes,
        ...subscriptionsFeaturesAdminRoute.routes,
        ...subscriptionsRequestsAdminRoute.routes,
      ],
    },
  ],
};
