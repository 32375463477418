export default {
  routes: [
    {
      path: "operation-expenses",
      name: "operation-expenses",
      component: () => import("./OperationExpenses.vue"),
      meta: {
        roles: [""],
        requiredModules: ["accounting-operation-expenses"],
      },
    },
    {
      path: "asset-expenses",
      name: "asset-expenses",
      component: () => import("./AssetExpenses.vue"),
      meta: {
        roles: [""],
        requiredModules: ["accounting-asset-expenses"],
      },
    },
    {
      path: "parts-expenses",
      name: "parts-expenses",
      component: () => import("./PartsExpenses.vue"),
      meta: {
        roles: [""],
        requiredModules: ["accounting-parts-expenses"],
      },
    },
    {
      path: "inventory-expenses",
      name: "inventory-expenses",
      component: () => import("./InventoryExpenses.vue"),
      meta: {
        roles: [""],
        requiredModules: ["accounting-inventory-expenses"],
      },
    },
  ],
};
