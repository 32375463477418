import authRoute from "@/views/Auth/auth.route";
import dashboardRoute from "@/views/Dashboard/dashboard.route";
import { createRouter, createWebHistory } from "vue-router";

import {
  authGuard,
  guestGuard,
  isVendor,
  isAdmin,
} from "@/helpers/router.helper";
import adminRoute from "../views/Admin/admin.route";
import vendorRoute from "../views/Vendor/vendor.route";
import { extractKeyword } from "../helpers/url.helper";
import { userHasPermissions } from "../helpers/router.helper";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      components: {
        default: () => import("@/views/Auth/WrapperAuth.vue"),
      },
      redirect: { name: "login" },
      children: [...authRoute.routes],
      beforeEnter: authGuard,
    },
    {
      path: "/connect",
      name: "connect",
      components: {
        default: () => import("@/views/Dashboard/WrapperDashboard.vue"),
      },
      beforeEnter: guestGuard,
      children: [...dashboardRoute.routes],
    },
    {
      path: "/admin",
      name: "admin-dashboard",
      components: {
        default: () => import("@/views/Admin/WrapperAdmin.vue"),
      },
      beforeEnter: isAdmin,
      children: [...adminRoute.routes],
      redirect: { name: "admin-owners" },
    },
    {
      path: "/vendor",
      name: "vendor-dashboard",
      components: {
        default: () => import("@/views/Vendor/WrapperVendor.vue"),
      },
      beforeEnter: isVendor,
      children: [...vendorRoute.routes],
      redirect: { name: "vendor-work-orders" },
    },
  ],
  scrollBehavior(to) {
    if (!to.meta.disableScrollBehavior) {
      return { top: 0 };
    }
  },
});

// Global Navigation Guard for Role-Based Access and Unknown Paths Handling
router.beforeEach(async function (to, from, next) {
  const currentPathKeyword = extractKeyword(window.location.href);

  // Handle Role-Based Redirects
  if (
    currentPathKeyword === "vendor" &&
    (to.name === "admin" || to.name === "connect")
  ) {
    return next({ name: "vendor-dashboard" });
  }
  if (
    currentPathKeyword === "admin" &&
    (to.name === "vendor" || to.name === "connect")
  ) {
    return next({ name: "admin-dashboard" });
  }
  if (
    currentPathKeyword === "connect" &&
    (to.name === "vendor" || to.name === "admin")
  ) {
    return next({ name: "connect-dashboard" });
  }

  // Handle Unknown Paths (Catch-All for undefined routes)
  if (!to.matched.length) {
    return next(`/${currentPathKeyword}`); // Redirect to the home page based on the path
  }

  // Proceed
  if (to.meta.permissions) {
    await userHasPermissions(to, from, next);
  } else {
    next(); // No permissions required; proceed to the route
  }
});

export default router;
